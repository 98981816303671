import { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import DeveloperTree from "./DeveloperTree";

function App() {
  const [language, setLanguage] = useState("nl");

  const content = {
    nl: {
      description:
        "Wij ontwikkelen <b>op maat gemaakte mobiele en webapps</b> die perfect aansluiten bij uw wensen—geen saaie templates! Met <b>meer dan 25 jaar ervaring</b> en <b>100+ succesvolle projecten</b>, vertalen we ideeën naar <b>creatieve, moderne en schaalbare</b> softwareoplossingen. Laten we samen iets bijzonders maken.",
      contact: "Neem contact op:",
      phone: "Telefoon",
      email: "E-mail",
    },
    en: {
      description:
        "We build <b>custom mobile and web apps</b> tailored to your unique needs—no boring templates here! With <b>25+ years of experience</b> and <b>100+ successful projects</b>, we turn ideas into <b>creative, modern, and scalable</b> software solutions. Let’s build something extraordinary together.",
      contact: "Contact me:",
      phone: "Phone",
      email: "Email",
    },
  };
  useEffect(() => {
    const scrollers = document.querySelectorAll(".scroller");
    const intervals = new Map(); // Store intervals for each scroller

    const startAutoScroll = (scroller) => {
      // Clear any existing interval for this scroller
      if (intervals.has(scroller)) {
        clearInterval(intervals.get(scroller));
      }

      // Start a new auto-scroll interval
      const interval = setInterval(() => {
        if (!scroller.matches(":hover") && !scroller.matches(":focus")) {
          scroller.scrollBy({ left: 1, behavior: "smooth" });
        }
      }, 20); // Adjust the speed by changing this interval

      intervals.set(scroller, interval); // Save the interval
    };

    scrollers.forEach((scroller) => {
      const scrollerInner = scroller.querySelector(".scroller__inner");
      const scrollerContent = Array.from(scrollerInner.children);

      // Clone items for seamless scrolling
      scrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true);
        duplicatedItem.setAttribute("aria-hidden", true);
        scrollerInner.appendChild(duplicatedItem);
      });

      // Start auto-scroll
      startAutoScroll(scroller);

      let interactionTimeout;

      const stopAutoScroll = () => {
        clearInterval(intervals.get(scroller));
        intervals.delete(scroller);
        if (interactionTimeout) {
          clearTimeout(interactionTimeout);
        }
      };

      const resumeAutoScroll = () => {
        interactionTimeout = setTimeout(() => {
          startAutoScroll(scroller);
        }, 3000); // Resume after 3 seconds
      };

      // Stop auto-scroll on interaction
      scroller.addEventListener("mousedown", stopAutoScroll);
      scroller.addEventListener("mouseup", resumeAutoScroll);
      scroller.addEventListener("touchstart", stopAutoScroll);
      scroller.addEventListener("touchend", resumeAutoScroll);

      // Enable manual scrolling and handle wheel events
      scroller.addEventListener("wheel", (event) => {
        stopAutoScroll();
        scroller.scrollBy({
          left: event.deltaY,
          behavior: "smooth",
        });
        resumeAutoScroll(); // Restart auto-scroll after manual scroll
      });

      // Restart auto-scroll on mouse leave
      scroller.addEventListener("mouseleave", resumeAutoScroll);
    });

    // Cleanup intervals on component unmount
    return () => {
      scrollers.forEach((scroller) => {
        if (intervals.has(scroller)) {
          clearInterval(intervals.get(scroller));
        }
      });
      intervals.clear();
    };
  }, []);

  return (
    <Router style={{}}>
      <div className="App" style={{}}>
        <Routes>
          <Route
            path="/"
            element={
              <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
                <main className="main-content" style={{ display: "flex", flex: 1 }}>
                  <img src="assets/krnic_logo.svg" alt="Krnic CommV" className="logo" />
                  <p className="description" dangerouslySetInnerHTML={{ __html: content[language].description }}></p>
                  <div className="language-switch">
                    <button className={language === "nl" ? "active" : ""} onClick={() => setLanguage("nl")}>
                      NL
                    </button>
                    <button className={language === "en" ? "active" : ""} onClick={() => setLanguage("en")}>
                      EN
                    </button>
                  </div>
                  <div className="contact">
                    <p>{content[language].contact}</p>
                    <p>
                      {content[language].phone}: <a href="tel:+32490364917">+32490364917</a>
                    </p>
                    <p>
                      {content[language].email}: <a href="mailto:info@krnic.be">info@krnic.be</a>
                    </p>
                  </div>
                </main>
                <div className="scroller" data-direction="right" data-speed="slow" style={{ display: "flex" }}>
                  <div className="scroller__inner">
                    <img src="assets/krnic_portfolio_01.png" alt="MealMate - Food ordering platform" />
                    <img src="assets/krnic_portfolio_02.png" alt="Sooperstock - USA webshop" />
                    <img src="assets/krnic_portfolio_03.png" alt="EpicApps - Logo" />
                    <img src="assets/krnic_portfolio_04.png" alt="Fishing PRO - Fishing App" />
                    <img src="assets/krnic_portfolio_05.png" alt="Krnic - AirQuality & Weather Chat" />
                    <img src="assets/krnic_portfolio_06.png" alt="CityX - Smart City App" />
                    <img src="assets/krnic_portfolio_07.png" alt="CityX - Smart City App" />
                    <img src="assets/krnic_portfolio_08.png" alt="CityX - Logo rebranding" />
                    <img src="assets/krnic_portfolio_10.png" alt="Pin'n'go - Travel App Logo" />
                    <img src="assets/krnic_portfolio_11.png" alt="Krnic - AirQuality & Weather Chat" />
                    <img src="assets/krnic_portfolio_12.png" alt="Krnic - AirQuality & Weather Chat" />
                    <img src="assets/krnic_portfolio_13.png" alt="OWL - App Logo" />
                    <img src="assets/krnic_portfolio_14.png" alt="wave - App Logo" />
                  </div>
                </div>
                <footer className="footer">
                  <p>&copy; 2024 Krnic CommV. All rights reserved.</p>
                </footer>
              </div>
            }
          />

          <Route path="/developer_tree_2024" element={<DeveloperTree />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
