import React, { useEffect, useState } from "react";
import * as d3 from "d3";

const GITHUB_API_URL = "https://api.github.com/graphql";
const GITHUB_TOKEN = "dc343c98351df69133e038042f6236a3556bc52b"; // Replace with your GitHub token

const ChristmasTree = () => {
  const [commitData, setCommitData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const query = `
        {
          user(login: "tomislavkrnic") {
            contributionsCollection {
              contributionCalendar {
                weeks {
                  contributionDays {
                    date
                    contributionCount
                  }
                }
              }
            }
          }
        }
      `;

      try {
        const response = await fetch(GITHUB_API_URL, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${GITHUB_TOKEN}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ query }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        // Flatten the contributions calendar data
        const days = data.data.user.contributionsCollection.contributionCalendar.weeks.flatMap((week) => week.contributionDays);

        setCommitData(days); // Save the full year's data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const commitColors = d3.scaleThreshold().domain([1, 5, 10, 20]).range(["#ebedf0", "#9be9a8", "#40c463", "#30a14e", "#216e39"]);

  const generateTree = () => {
    const data = [];
    const startX = 50;
    const startY = 50;
    let row = 1;

    for (let i = 0; i < commitData.length; i++) {
      const x = startX + (i % row) * 15;
      const y = startY + row * 15;
      data.push({
        x,
        y,
        color: commitColors(commitData[i].contributionCount),
        date: commitData[i].date,
      });

      if (i % row === row - 1) row++;
    }

    return data;
  };

  const treeData = generateTree();

  return (
    <svg width="800" height="600" style={{ background: "#fff" }}>
      {treeData.map((d, i) => (
        <rect key={i} x={d.x} y={d.y} width="10" height="10" fill={d.color} stroke="#000" strokeWidth="0.5">
          <title>{`${d.date}: ${d.color}`}</title>
        </rect>
      ))}
      {/* Base */}
      <rect x="300" y="550" width="50" height="50" fill="#8B4513" />
    </svg>
  );
};

export default ChristmasTree;
